// main.js

$('body *').each(function () { var $this = $(this); if ($this.css('text-transform') == 'uppercase') { $this.text($this.text().replace(/i/g, 'İ').replace(/ı/g, 'I')); } })

$('.menu-footer-menu-container li:last-child').addClass('last').prev().addClass('last');

// responsive menu
$(document).on('click', 'body.show-menu li.menu-item-has-children > a', undefined, function (e) {
  e.preventDefault();

  var $this = $(this);
  var $menu = $this.siblings('.sub-menu');

  $('.menu-main-menu-container .sub-menu').not($menu).slideUp();

  $menu.slideToggle();

  return false;
});

$('a#menu').click(function (e) {
  e.preventDefault();
  $('body').toggleClass('show-menu');
  return false;
});

$(document).ready(function () {
  if ($.fn.bxSlider) {
    var manual = false;

    var slider = $('.bxslider').bxSlider({
      'captions': false,
      'controls': false,
      'infiniteLoop': false,
      'adaptiveHeight': true,
      'auto': true,
      'autoPause': 5000,
      'touchEnabled': false,
      'onSliderLoad': function () {
        $('.bxslider').addClass('loaded');
        $('#slideshow-container .bx-pager-item').click(function () { manual = true; });
      },
      'onSlideAfter': function ($el, o, n) {
        if ($el.siblings('li').length === n + 1) {
          window.setTimeout(function () { if (!manual) slider.goToSlide(0); }, 8000);
        }
      },
    });
  }
});

$('.calendar.list').each(function () {
  var $this = $(this);
  var $wrapper = $this.find('.calendar-wrapper');
  var $calendar = $this.find('ul');

  var scroll = function (e) {
    var $link = $(e.target);
    position = parseInt($calendar.css('top'));

    position += $link.hasClass('down') ? -$wrapper.height() : $wrapper.height();

    if (position <= 0 && position >= $wrapper.height() - $calendar.height()) {
      $calendar.animate({'top': position });
    }

    e.preventDefault();
    return false;
  }

  $('<span></span>').addClass('scroll down').click(scroll).appendTo($this);
  $('<span></span>').addClass('scroll up').click(scroll).appendTo($this);
});

$('.calendar.cal').each(function () {
  var $cal = $(this);
  $cal.find('.day.has-events')
    .tipsy({ trigger: 'manual', html: true, title: function () { return $(this).children('.events').html() } })
    .on('mouseenter click', function (e) {
      var $day = $(this);

      $day.closest('.days').find('.day.has-events').each(function () {
        $(this).tipsy('hide');
      });

      $day.closest('.days').find('.day.active').removeClass('active');

      $day.addClass('active').tipsy('show');

      window.clearTimeout($day.data('timer'));

      var timer = window.setTimeout(function() {
        $day.removeClass('active').tipsy('hide');
      }, 10000);

      $day.data('timer', timer);
    });
});

$('select[name$="_city"]').change(function (e) {
  var $this = $(this);
  var id = $this.val();
  if (id == 0) return false;

  $.ajax({
    url: '/districts.php',
    dataType: 'json',
    data: { id: id },
    success: function (data) {
      var $target = $('select[name$=_district][name^=' + $this.attr('name').replace(/_.*/, '') + ']');
      var items = new Array();

      if ($target.attr('data-value') == '') {
        items.push('<option value="" selected="selected"></option>');
      } else {
        items.push('<option value=""></option>');
      }

      $.each(data, function(key, val) {
        items.push('<option ' + ($target.attr('data-value') == key ? 'selected="selected"' : '') + ' value="' + key + '">' + val + '</option>');
      });

      $target.html(items.join(''));
    }
  });
}).change();

$('#member').autocomplete({
  source: function(request, response) {
    'use strict';
    $.getJSON('/speakers.php', request, response);
  }
});

$('.video-list').each(function () {
  var $this = $(this);
  $this.data('animation', $this.html());

  var apikey = 'AIzaSyANJvu-mumMaJjzfO8ZZdApobakU7epX3c';
  var playlistId = 'UUy5ecCr9Zjtotn4PyBq1TwA';

  var loader = function (page) {
    if (!page) page = '';

    $.ajax({
      url: 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails%2Cstatus&maxResults=10&pageToken=' + page + '&playlistId=' + playlistId + '&key=' + apikey,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(errorThrown);
      },
      success: listVideos
    });
  };

  $this.data('loader', loader);

  loader();
});

function listVideos (data) {
  var links = new Array();
  for (var i = 0; i < data.items.length; i++) {
    var item = data.items[i].snippet;
    var video = 'https://www.youtube.com/embed/' + item.resourceId.videoId + '?rel=0&showinfo=0&autoplay=1';
    var title = item.title;
    var description = item.description;
    var thumb = item.thumbnails.default;

    links.push('<li><a class="image" href="' + video + '" title="' + title + '"><img src="' + thumb['url']  + '" width="' + (parseInt(thumb['width'])*0.8) + '" height="' + (parseInt(thumb['height'])*0.8) + '" alt="' + title + '" /></a><a class="caption" href="' + video + '" title="' + title + '">' + title + '</a></li>');
  }

  var pager = new Array();

  if (data.prevPageToken) {
    pager.push('<a href="#" class="prev" data-page=' + data.prevPageToken + '>&larr; Önceki Sayfa</a>');
  }

  if (data.nextPageToken) {
    pager.push('<a href="#" class="next" data-page=' + data.nextPageToken + '>Sonraki Sayfa &rarr;</a>');
  }

  var $this = $('.video-list');

  $this.html(links.join('') + '<li class="pager">' + pager.join('') + '</li>');

  $this.find('.pager a').click(function (e) {
    e.preventDefault();

    var $link = $(e.target);

    $this.html($this.data('animation'));
    $('body').animate({ scrollTop: 0 }, { duration: 500, complete: function () { var loader = $this.data('loader'); loader($link.data('page')); } });

    return false;
  });

  if ($.fn.fancybox) {
    $this.find('a.image, a.caption').fancybox({
      type: 'iframe',
      showCloseButton: true,
      showNavArrows: true,
      hideOnOverlayClick: true,
      overlayColor: '#000000',
      overlayOpacity: 0.9,
      enableEscapeButton: true
    });
  }
}
